import {
  Box,
  Button,

  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
  
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";



import { getDb } from "../../store/slices/dbSlice";
import { useSelector } from "react-redux";
import { getIsAdmin, getUserId } from "../../store/slices/userSlice";
import DataService from "../../Services/DataService";

import SensorChart from "./SensorChart";


// Defina a interface para os valores
interface Prescricao {
  recommendedValue: any;
  valor_recomendado: number;
  valor_atual: number;
}

const Modal = (props: any) => {
  const userId = useSelector(getUserId);
  const isAdmin = useSelector(getIsAdmin);
  const theme = useTheme();
  
  const [scenarioVariables, setScenarioVariables] = useState<Array<any>>([]);
  const [scenarioDataList, setScenarioDataList] = useState<Array<any>>([]);
  const [timestamp, setTimestamp] = useState<any>(moment());
  const [openModal, setOpenModal] = useState(false);
  const [openModalJustify, setOpenModalJustify] = useState(false);
  const [justification, setJustification] = useState("");
  const db = useSelector(getDb);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        const sensors = await DataService.getSensorsWithAlarmDetails(props.gemeo.id);
        setScenarioVariables(sensors);

        // Supondo que você tenha um objeto de prescrições
        

        const fetchedDataList = await Promise.all(sensors.map(async (variable) => {
            const scenarioData = await NewDi2winDataService.getLastNInfoInRange(
                db.dbName,
                db.collection,
                variable.number,
                variable.device_id,
                moment(props.alerta?.first_alert_time),
                moment(props.alerta?.first_alert_time).add(30, "minute"),
                "1"
            );

            let lastData = scenarioData?.pop();
            return {
                ...lastData,
                ...variable, // Adicionando o número do sensor

                

            };
        }));

        // Criar um objeto para armazenar os dados dos sensores
        const sensorData: any = []; // Definindo como um objeto
        fetchedDataList.forEach(data => {
            if (data && scenarioVariables.length > 0 && scenarioVariables[parseInt(data.number)]) {
               
              const sensorNumber = data.number; // Número do sensor
                
                // console.log(scenarioVariables[parseInt(sensorNumber)]);
              

                sensorData[sensorNumber] = {
                    unit: data.unit,
                    device_id: scenarioVariables[parseInt(sensorNumber)]?.device_id,
                    number: scenarioVariables[parseInt(sensorNumber)]?.number,
                    id:scenarioVariables[parseInt(sensorNumber)]?.id,
                    name: scenarioVariables[parseInt(sensorNumber)]?.name,
                    currentValue: data.value, // Usar valor atual da prescrição
                    recommendedValue: scenarioVariables[parseInt(sensorNumber)]?.valor_recomendado, // Usar valor recomendado da prescrição
                    
                };
            }
        });
        // console.log(sensorData);
        setScenarioDataList(sensorData); // Armazena o objeto no estado
    };

    fetchData(); // Chama a função para buscar os dados inicialmente

    const interval = setInterval(() => {
      fetchData();
      
    }, 120000);
    return () => {
      clearInterval( interval);
    };
  }, [db.collection, db.dbName, props.alerta?.first_alert_time, props.gemeo.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTimeDuration = (startTime: moment.Moment, endTime: moment.Moment) => {
    return `${moment.duration(endTime.diff(startTime)).days()}d ${moment.duration(endTime.diff(startTime)).hours()}h ${moment.duration(endTime.diff(startTime)).minutes()}m ${moment.duration(endTime.diff(startTime)).seconds()}s`;
  };

  const timeCurrent = props.alerta?.finished
    ? calculateTimeDuration(moment(props.alerta?.first_alert_time), moment(props.alerta?.last_alert_time))
    : calculateTimeDuration(moment(props.alerta?.first_alert_time), timestamp);

  const handleClose = () => {
    if (isAdmin) {
      setOpenModal(false);
    }
    setOpenModal(false);
  };

  const handleCiente = async (alarmId) => {
    if (userId) {
      try {
        await AlertsAlarmsService.ackAlarm(userId, alarmId);
        setOpenModal(false);
      } catch (error) {
        try {
          await AlertsAlarmsService.ackAlert(userId, alarmId);
          setOpenModal(false);
        } catch (error) {
          // Handle error if needed
        }
      }
    }
  };

  const handleCienteJustificar = async (alarmId, justification) => {
    if (userId) {
      try {
        await AlertsAlarmsService.justifyRecommendation(userId, alarmId, justification);
        setOpenModal(false);
      } catch (error) {
        // Handle error if needed
      }
    }
  };

  const handleCloseJustify = () => {
    setOpenModalJustify(false);
  };

  useEffect(() => {
    if (props.alerta?.acknowledged) {
      setOpenModal(false);
    }

    if (!props.alerta?.acknowledged && props.alerta?.finished) {
      setOpenModal(true);
    }

    if (!props.alerta?.acknowledged && !props.alerta?.finished ) {
      setOpenModal(true);
    }
    if (!props.alerta) {
      setOpenModal(false);
    }
  }, [props.alerta]);

 


  const getAlertStatus = () => {
    if (props.alerta.predictions) {
      return "ALERTA";
    } else if (props.alerta.finished) {
      return "CORREÇÃO";
    } else {
      return "ALARME";
    }
  };


  return (
    <>
      <Dialog
        onClose={handleCloseJustify}
        open={openModalJustify}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          fontWeight: 400,
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
          fontSize: "16px",
          fontFamily: "Roboto",
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1a8f9d",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "1vh",
              justifyContent: "space-around",
              alignItems: "center",
              color: theme.palette.mode === "dark" ? "#fff" : "#000",
            }}
          >
            Recomendação recusada
          </div>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              multiline
              rows={4}
              autoFocus
              margin="dense"
              id="justification"
              label="Justificativa"
              type="text"
              fullWidth
              placeholder="Insira o motivo de não seguir a recomendação"
              onChange={(e) => setJustification(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
          }}
        >
          <Button
            sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
            onClick={handleCloseJustify}
          >
            Fechar
          </Button>
          <Button
            sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
            onClick={() => {
              handleCienteJustificar(props.alerta.id, justification);
              setOpenModalJustify(false);
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClose}
        open={openModal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          fontWeight: 400,
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
          fontSize: "16px",
          fontFamily: "Roboto",
        }}
      >
        <DialogTitle
          sx={{
            background: props.alerta?.predictions
              ? "rgba(255, 203, 5, 0.38)"
              : props.alerta?.finished
              ? "rgba(131, 171, 81, 0.38)"
              : "#C0404061",
            padding: "0",
          }}
        >
          <div
            style={{
              backgroundColor: props.alerta?.predictions
                ? "rgba(255, 203, 5)"
                : props.alerta?.finished
                ? "#83AB51"
                : "#C04040",
              padding: "3vh 0 3vh 0",
              width: "1vw",
              float: "left",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              display: "flex",
              margin: "3vh",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {props.alerta !== undefined && (
              <div
                style={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                }}
              >
                {getAlertStatus()}
              </div>
            )}
          </div>
        </DialogTitle>
        
        <DialogContent
          sx={{
            fontWeight: 400,
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
            fontSize: "16px",
            fontFamily: "Roboto",
            backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
          }}
        >
          <>
            

            {props.alerta?.predictions &&
              props.alerta !== undefined &&
              props.gemeo !== undefined && (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{
                    padding: "1vh",
                    fontWeight: 400,
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                  }}
                >
                  <b>Unidade:</b> <span>Recife</span>
                  <br />
                  <b>Setor:</b> <span>{props.gemeo?.name}</span>
                  <br />
                  <b>Descrição:</b>{" "}
                  <span>
                    Eficiência do consumo de Gás Natural sairá do intervalo
                    aceitável
                  </span>
                  <br />
                  <br />
                  <b>Início:</b>
                  <span>
                    {` ${new Date(
                      props.alerta?.first_alert_time
                    ).toLocaleDateString()}  ${new Date(
                      props.alerta?.first_alert_time
                    ).toLocaleTimeString()}  `}
                  </span>
                  <br />
                  {props.alerta?.finished && (
                    <>
                      <b>Normalizado em:</b>{" "}
                      <span>{`${new Date(
                        new Date(props.alerta?.last_alert_time).getTime() -
                          new Date(props.alerta?.first_alert_time).getTime()
                      ).getMinutes()}m ${new Date(
                        new Date(props.alerta?.last_alert_time).getTime() -
                          new Date(props.alerta?.first_alert_time).getTime()
                      ).getSeconds()}s`}</span>
                    </>
                  )}
                  {!props.alerta?.finished && (
                    <>
                      <b>Duração:</b> <span>{timeCurrent}</span>
                    </>
                  )}
                </DialogContentText>
              )}

            {props.alerta !== undefined && (
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  padding: "1vh",
                  fontWeight: 400,
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                }}
              >
                <b>Unidade:</b> <span>Recife</span>
                <br />
                <b>Setor:</b> <span>{props.gemeo?.name}</span>
                <br />
                <b>Descrição:</b>{" "}
                <span>
                  Eficiência do consumo de Gás Natural{" "}
                  {props.alerta?.finished ? "dentro" : "fora"} do intervalo
                  aceitável
                </span>
                <br />
                <span>
                  (
                  {parseFloat(
                    props.alerta?.finished
                      ? props.alerta?.last_value < 0
                        ? 0
                        : props.alerta?.last_value
                      : props.alerta?.first_value
                  ).toFixed(2)}{" "}
                  Nm³/ton)
                </span>
                <br />
                <b>Início:</b>
                <span>
                  {` ${new Date(
                    props.alerta?.first_alert_time
                  ).toLocaleDateString()}  ${new Date(
                    props.alerta?.first_alert_time
                  ).toLocaleTimeString()}  `}
                </span>
                <br />
                {props.alerta?.finished && (
                  <>
                    <b>Normalizado em:</b>{" "} <span>{calculateTimeDuration(moment(props.alerta.first_alert_time), moment(props.alerta.last_alert_time))}</span>
                  </>
                )}
                {!props.alerta?.finished && (
                  <>
                    <b>Duração:</b> <span>{timeCurrent}</span>
                  </> 
                )}

                {/* Tabela de Sensores */}

               
              {showChart ? 
                    !props.alerta?.finished && 
                
                    <SensorChart scenarioDataList={scenarioDataList} alerta={props.alerta} />
                  
                   
                      //  Object.entries(scenarioDataList).map(([sensorNumber, data]) => (
                      //      <div key={data?.id} >
                      //          {data?.name || "Desconhecido"}
                      //          {data?.currentValue !== undefined ? data.currentValue.toFixed(2) : "N/A"}
                      //          {props.alerta?.message && 
                      //          Object.entries(JSON.parse(props.alerta?.message)).filter(([key]) => key === sensorNumber).map(([key, value]) => {
                      //            // Use a asserção de tipo para informar ao TypeScript que value é do tipo Prescricao
                      //            const prescricao = value as Prescricao; // Asserção de tipo
     
                      //            return (
                      //              <>
                                   
                                    
                      //             {`${prescricao.valor_atual.toFixed(2)} `}
                      //             {`${prescricao.valor_recomendado.toFixed(2)} `}
                                   
                              
                                 
                      //            </>
                      //            );
                      //          })}
                               
                      //          {data.unit?.abbreviation || "N/A"}
                      //      </div>
                      //  ))
                   
              
               
                :
                !props.alerta?.finished && 
                
                  <div >
                 <Table  sx={{ }} size="small" aria-label="a dense table">
                 <TableHead>
                     <TableRow>
                         <TableCell  align="left">Sensor</TableCell>
                         <TableCell  align="right">Cenario</TableCell>
                         <TableCell  align="right">Atual</TableCell>
                         <TableCell  align="right">Recomendado</TableCell>
                         <TableCell  align="right"></TableCell>
                     </TableRow>
                 </TableHead>
                 <TableBody>
                     {Object.entries(scenarioDataList).map(([sensorNumber, data]) => (
                         <TableRow selected key={data?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                             <TableCell component="th" scope="row"><Typography
                                
                                
                                gutterBottom
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  
                                  whiteSpace: "nowrap",
                                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                                }}>{data?.name || "Desconhecido"}
                               </Typography></TableCell>
                             <TableCell align="right" >
                               <Typography
                               
                                gutterBottom
                                sx={{
                                 fontSize: "14px",
                                 fontWeight: 400,
                                 
                                 whiteSpace: "nowrap",
                                 color: theme.palette.mode === "dark" ? "#fff" : "#000",
                               }}>
                                 {data?.currentValue !== undefined ? data.currentValue.toFixed(2) : "N/A"}
                               </Typography></TableCell>
                             {props.alerta?.message && 
                             Object.entries(JSON.parse(props.alerta?.message)).filter(([key]) => key === sensorNumber).map(([key, value]) => {
                               // Use a asserção de tipo para informar ao TypeScript que value é do tipo Prescricao
                               const prescricao = value as Prescricao; // Asserção de tipo
   
                               return (
                                 <>
                                 <TableCell
                                   key={key}
                                   align="right" 
                                   sx={{
                                    
                                   }}
                                 >
                                   
                                   <Typography 
                                  
                                   gutterBottom
                                   sx={{
                                     fontSize: "14px",
                                     fontWeight: 400,
                                     
                                     whiteSpace: "nowrap",
                                     color: theme.palette.mode === "dark" ? "#fff" : "#000",
                                   }}>
                                     {`${prescricao.valor_atual.toFixed(2)} `}
                                   </Typography>
                                 
                                 </TableCell>
                                 <TableCell
                                 key={key}
                                 align="right" 
                                 sx={{
                                  
                                 }}
                               >
                                 
                                 <Typography 
                                 
                                 gutterBottom
                                 sx={{
                                   fontSize: "14px",
                                   fontWeight: 400,
                                   
                                   whiteSpace: "nowrap",
                                   color: theme.palette.mode === "dark" ? "#fff" : "#000",
                                 }}>
                                   {`${prescricao.valor_recomendado.toFixed(2)} `}
                                 </Typography>
                               
                               </TableCell>
                               </>
                               );
                             })}
                             
                            
                             
                             <TableCell align="center">{data.unit?.abbreviation || "N/A"}</TableCell>
                         </TableRow>
                     ))}
                 </TableBody>
             </Table>
             </div>
                }
            
            
              </DialogContentText>
            )}

           
           


            {/* {props.alerta?.predictions && (
              <>
                <b>Predicão:</b>
                <ul className="listaPrescricao"></ul>

                {props.alerta !== undefined &&
                  Object.entries(props.alerta?.predictions).map((e) => {
                    if (arrayOfPrescriptions.includes(e[0])) {
                      const tmp = e[1] as any;

                      return (
                        <div
                          key={e[0]}
                          style={{
                            marginLeft: "3vw",
                            fontWeight: 400,
                            color:
                              theme.palette.mode === "dark" ? "#fff" : "#000",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                          }}
                        >
                          <span>{sensorName(String(e[0]))}</span>
                          <div
                            style={{
                              marginLeft: "1vw",
                              fontWeight: 400,
                              color:
                                theme.palette.mode === "dark" ? "#fff" : "#000",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                            }}
                          >
                            <span>{`Inicial: ${tmp["Valor atual"]} ${
                              unitDictionary[e[0]]
                            }`}</span>
                            <br></br>
                            <span>{`Recomendada: ${tmp["Valor recomendado"]} ${
                              unitDictionary[e[0]]
                            }`}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </>
            )} */}

            {
              props.alerta &&
              !props.alerta?.finished && (
                // <>
                //   <Typography
                //     style={{
                //       fontWeight: 400,
                //       color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //       fontSize: "16px",
                //       fontFamily: "Roboto",
                //     }}
                //     component="div"
                //   >
                //     Prescrição:
                //   </Typography>
                //   <Box
                //     sx={{
                //       pl: 3,
                //       mt: 1,
                //       fontWeight: 400,
                //       color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //       fontSize: "16px",
                //       fontFamily: "Roboto",
                //     }}
                //   >
                //     {props.alerta?.message && 
                //       // <Tooltip
                //       //   title={JSON.stringify(JSON.parse(props.alerta?.message), null, 2)}
                //       //   arrow
                //       //   sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
                //       // >
                //       //   <span>
                //           Object.entries(JSON.parse(props.alerta?.message)).map(([key, value]) => {
                //             // Use a asserção de tipo para informar ao TypeScript que value é do tipo Prescricao
                //             const prescricao = value as Prescricao; // Asserção de tipo

                //             return (
                //               <Box
                //                 key={key}
                //                 sx={{
                //                   mb: 0,
                //                   fontWeight: 400,
                //                   color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //                   fontSize: "16px",
                //                   fontFamily: "Roboto",
                //                 }}
                //               >
                //                 <Typography 
                //                 variant="body1"
                //                 gutterBottom
                //                 sx={{
                //                   color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //                 }}>
                //                   {scenarioDataList.find((sensor) => sensor?.number === key)?.name || "Desconhecido"}
                                  
                //                 </Typography>
                //                 <Typography 
                //                 variant="body1"
                //                 gutterBottom
                //                 sx={{
                //                   color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //                 }}>
                //                   {`Valor Atual: ${prescricao.valor_atual.toFixed(2)} `}
                //                 </Typography>
                //                 <Typography 
                //                 variant="body1"
                //                 gutterBottom
                //                 sx={{
                //                   color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //                 }}>
                //                   {`Valor Recomendado: ${prescricao.valor_recomendado.toFixed(2)} `}
                //                 </Typography>
                //               </Box>
                //             );
                //           })}
                //         {/* </span>
                       
                //       </Tooltip> */}
                    
                     
                //   </Box>

                //   <Box
                //     sx={{
                //       mt: 3,
                //       fontWeight: 400,
                //       color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //       fontSize: "16px",
                //       fontFamily: "Roboto",
                //     }}
                //   >
                //     <Typography
                //       style={{
                //         fontWeight: 400,
                //         color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //         fontSize: "16px",
                //         fontFamily: "Roboto",
                //       }}
                //       component="div"
                //     >
                //       Cenário:
                //     </Typography>
                //     <Box
                //       sx={{
                //         pl: 3,
                //         mt: 1,
                //         fontWeight: 400,
                //         color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //         fontSize: "16px",
                //         fontFamily: "Roboto",
                //       }}
                //     >
                //       {scenarioDataList.length && scenarioDataList.map((scenarioData) => {
                //         console.log(scenarioData);
                        
                //         return <Box
                //             key={scenarioData.sensorId}
                //             sx={{
                //               mb: 2,
                //               fontWeight: 400,
                //               color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //               fontSize: "16px",
                //               fontFamily: "Roboto",
                //             }}
                //           >
                //             <Typography
                //               variant="body1"
                //               gutterBottom
                //               sx={{
                //                 color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //               }}
                //             >
                              
                //               {`${scenarioData.name}: ${ scenarioData.currentValue } ${scenarioData.unit !== undefined ? String(scenarioData.unit.abbreviation) : ""} `}
                //             </Typography>
                //           </Box>
                        
                //       })}

                //       {scenarioDataList.length === 0 && (
                //         <CircularProgress />
                //       )}
                      
                      
                //     </Box>
                //   </Box>
                // </>
                <></>)
              }
            
            
                
           
         
        
              
          </>
        </DialogContent>

        <DialogActions
          sx={{
            color: theme.palette.mode === "dark" ? "#fff" : "#000",
            backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
          }}
        >
          {isAdmin &&
            (props.alerta?.finished ? (
               <><Button
                sx={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                }}
                onClick={() => {
                  if (props.alerta?.finished && handleClose) {
                    handleClose();
                    // handleCiente(props.alerta?.id);
                  }
                }}
              >
                Fechar
              </Button>
           
         </>
            ) : (
              <>
                    <Button onClick={() => setShowChart(!showChart)} sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>
                        {showChart ? "Mostrar Tabela" : "Mostrar Gráfico"}
                    </Button>
                <Button
                  sx={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={handleClose}
                >
                  Fechar
                </Button>
                <Button
                  sx={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={() => {
                    handleCiente(props.alerta?.id);
                  }}
                  disabled
                >
                  Ciente
                </Button>
              </>
            ))}

          {!isAdmin &&
            (props.alerta?.finished ? (
              <>
                <Button
                  sx={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={handleClose}
                >
                  Fechar
                </Button>
                <Button
                  sx={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={() => {
                    handleCiente(props.alerta?.id);
                  }}
                >
                  Ciente
                </Button>
              </>
            ) : (
              <>
                {!props.alerta?.acknowledged && (
                  <>
                    <Button
                      sx={{
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        setOpenModalJustify(true);
                      }}
                    >
                      Ciente-Justificar
                    </Button>
                    <Button
                      sx={{
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        handleCiente(props.alerta?.id);
                      }}
                    >
                      Ciente-Seguir
                    </Button>
                  </>
                )}

                {props.alerta?.acknowledged && (
                  <>
                    <Button
                      sx={{
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      }}
                      onClick={handleClose}
                    >
                      Fechar
                    </Button>
                    <Button
                      sx={{
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        handleCiente(props.alerta?.id);
                      }}
                      disabled
                    >
                      Ciente
                    </Button>
                  </>
                )}
              </>
            ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
