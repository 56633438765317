import { t } from "i18next";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

interface Prescricao {
  valor_atual: number;
  valor_recomendado: number;
}


const SensorChart: React.FC<{ scenarioDataList: Record<string, any>, alerta?: { message: string } }> = ({ scenarioDataList, alerta }) => {
  const chartData = Object.entries(scenarioDataList).map(([sensorNumber, data]) => {
    const alertData = alerta?.message
      ? Object.entries(JSON.parse(alerta.message)).find(([key]) => key === sensorNumber)?.[1] as Prescricao
      : null;

    return {
      sensor: data?.name || "Desconhecido",
      currentValue: data?.currentValue !== undefined ? parseFloat(data.currentValue.toFixed(2)) : null,
      recommendedValue: alertData ? parseFloat(alertData.valor_recomendado.toFixed(2)) : null,
      unit: data.unit?.abbreviation || "N/A",
    };
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <XAxis 
      dataKey="sensor" 
      hide
      tick={{ fontSize: 12}} 
      angle={-90} 
      interval={0}
      tickLine={false}
      tickMargin={8}
      axisLine={false}
      type="category"
      padding={{ left: 0, right: 0 }}

    />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="currentValue" fill="#e7b6b6" name="Valor Atual" />
        <Bar dataKey="recommendedValue" fill="#0c5e5a" name="Valor Recomendado" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SensorChart;